import React, { useContext, useEffect, useRef, useState } from 'react';
import ayeCampaignLogo from '../../assets/images/common/aye-campaign-logo.svg';
import headset from '../../assets/images/common/headset.svg';
import ayeAssistantIcon from "../../assets/images/common/Aye-Assistant-btn.svg";
import { useNavigate } from 'react-router-dom';
import AssistantWidget from '../campaign/AssistantWidget';
import { AuthContext } from '../../_providers/AuthProvider';
import { useModal } from 'react-modal-hook';
import UpdateUserDetailModel from '../model/UpdateUserDetailModel';

export default function Header() {

    const { status, session } = useContext(AuthContext)
    const [openSuccesPopUp, hideSuccesPopUp] = useModal(() => (
        <UpdateUserDetailModel hide={() => { hideSuccesPopUp(); }} session={session} />
    ), [session]);
    const [user, setUser] = useState({})
    const navigate = useNavigate();
    const [chatAssist, setChatAssist] = useState(false);
    const [toggle, setToggle] = useState(false);
    const dropdownRef = useRef(null);
    const avatarRef = useRef(null);
    useEffect(() => {
        setUser({
            name: `${session?.data?.userInfo?.firstName} ${session?.data?.userInfo?.lastName}`,
            email: session?.data?.userInfo?.email,
            organization: session?.data?.userInfo?.org?.name
        })
    }, [session])
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && avatarRef.current && !avatarRef.current.contains(event.target)) {
                setToggle(false);
            }
        }
        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, []);
    const getInitials = (name) => {
        if (!name) {
            return '';
        }
        const initials = name.split(' ').map(part => part[0]).join('');
        return initials.toUpperCase();
    };

    return (
        <header className="sticky top-0 w-full flex justify-between items-center px-5 py-2 bg-white shadow-md border-b border-light-grey-1 z-10">
            <AssistantWidget chatAssist={chatAssist} setChatAssist={setChatAssist} campaignId={null} />
            <img onClick={() => navigate("/dashboard")} className='cursor-pointer' src={ayeCampaignLogo} alt='Aye Campaign Logo' />
            <div className="flex justify-center items-center gap-5 mr-5 relative">
                <button type="button" onClick={() => setChatAssist(!chatAssist)} className='btn text-sm bg-gradient-to-r from-blue-600 via-purple-600 to-green-400'>
                    <img className="w-5 h-4" src={ayeAssistantIcon} alt="Aye Assistant Icon" />
                    <span className="text-white text-base font-semibold">Aye Assistant</span>
                </button>
                <img ref={dropdownRef} width={25} height={25} className='cursor-pointer' src={headset} onClick={() => setToggle(!toggle)} alt='Headset' />
                <div className={`${toggle ? 'block' : 'hidden'} bg-white absolute right-0 top-12 z-10 shadow-lg border border-gray-200 rounded-md`}>
                    <ul className="text-sm w-64 text-left">
                        <li className="cursor-pointer font-medium text-gray-900 border-b border-gray-200 py-2 px-4">
                            <span className="block text-base font-semibold break-words">{user?.name}</span>
                            <span className="block text-sm text-gray-600 break-words">{user?.email}</span>
                            <span className="block text-sm text-gray-600 break-words">{user?.organization}</span>
                        </li>
                        <li className="cursor-pointer font-medium text-red-600 hover:text-red-800 transition-all py-2 px-4" onClick={() => {
                            openSuccesPopUp()
                        }}>
                            Update Profile
                        </li>
                        <li className="cursor-pointer font-medium text-red-600 hover:text-red-800 transition-all py-2 px-4" onClick={() => {
                            status?.logout();
                            navigate("/")
                        }}>
                            Logout
                        </li>
                    </ul>
                </div>
                {session?.data?.userInfo?.profileUrl ?
                    <img ref={avatarRef} className='w-12 h-12 rounded-full cursor-pointer' src={session?.data?.userInfo?.profileUrl} alt='User Avatar' onClick={() => setToggle(!toggle)} />
                    : <div ref={avatarRef} className='relative flex justify-center items-center w-12 h-12 border border-gray-300 rounded-full bg-[#7F5FEE] cursor-pointer' onClick={() => setToggle(!toggle)}>
                        <div className='absolute top-0 right-1 w-3 h-3 rounded-full bg-green-500'></div>
                        <span className='text-white text-base font-semibold'>{getInitials(user?.name)}</span>
                    </div>}
            </div>
        </header>
    );
};
