import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import actionIcon from "../../assets/images/brand-management/action-Icon.svg"

import { useModal } from 'react-modal-hook';
import searchIcon from "../../assets/images/common/Search.svg"
import sortIcon from "../../assets/images/campaigns/sort.svg"
import downloadIcon from "../../assets/images/campaigns/download.svg"
import AddBrandVoiceModel from '../model/AddBrandVoiceModel';
import axios from 'axios';
import { Path } from '../../helper/path';
import { AuthContext } from '../../_providers/AuthProvider';
import BrandVoiceTextModel from '../model/BrandVoiceTextModel';
import BrandVoiceLinkModel from '../model/BrandVoiceLinkModel';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate } from 'react-router-dom';
import BrandVoiceFileModel from '../model/BrandVoiceFileModel';
import { FullScreenLoader } from '../common/FullScreenLoader';
import { ArchiveIcon, ExportIcon, FilterIcon, SearchIcon } from '../../helper/SvgIcons';
import Swal from 'sweetalert2';

export default function BrandVoice() {
    const navigate = useNavigate()
    const [filterType, setFilterType] = useState(false);
    const [isLoader, setIsLoader] = useState(false)
    const [toggle, setToggle] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const { session } = useContext(AuthContext)
    const [brandData, setBrandData] = useState([])
    const [hoverColor, setHoverColor] = useState(null)
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [showSetting, setShowSetting] = useState(false);
    const [checkboxes, setCheckboxes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [filter, setFilter] = useState({ name: '' })


    const [openSuccesPopUp, hideSuccesPopUp] = useModal(() => (
        <AddBrandVoiceModel hide={() => { hideSuccesPopUp(); }} session={session} refresh={refresh} setRefresh={setRefresh} />
    ));

    // Function to handle individual checkbox click
    const handleCheckboxClick = (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setCheckboxes(updatedCheckboxes);

        // Check if all checkboxes are checked
        const allChecked = updatedCheckboxes.every((checkbox) => checkbox);
        setSelectAll(allChecked);
    };

    // Function to handle "Select All" checkbox click
    const handleSelectAllClick = () => {
        const allChecked = !selectAll;
        setSelectAll(allChecked);

        // Update the state of all checkboxes
        const updatedCheckboxes = new Array(brandData.length).fill(allChecked);
        setCheckboxes(updatedCheckboxes);
    };

    async function BrandVoiceData(pageNumber) {
        setIsLoader(true)
        await axios.get(Path.BrandVoiceTextBaseNew,
            {
                params: {
                    page: pageNumber,
                    size: 10,
                    sortKey: sortConfig.key,
                    sortOrder: sortConfig.direction,
                    name: filter.name
                },
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            setPageCount(success?.data?.totalPages)
            setBrandData(success?.data?.brands)
            setIsLoader(false)
        }).catch((error) => {
            console.log(error)
            setIsLoader(false)
        })
    }

    const sortedBrandListing = useMemo(() => {
        let sortableItems = [...brandData];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [brandData, sortConfig]);

    const requestSort = key => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
        BrandVoiceData(selectedPage);
    };

    useEffect(() => {
        session?.data?.token && BrandVoiceData(currentPage)
    }, [refresh, session?.data?.token])

    useEffect(() => {
        let intervalId;
        const anyInProgress = brandData.some(brand => brand.status === 'INPROGRESS');

        if (anyInProgress) {
            intervalId = setInterval(BrandVoiceData, 5000);
        }

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [brandData]);
    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            {
                brandData?.length === 0 ?
                    <>
                        <div className="min-h-full flex flex-col gap-10 text-center justify-center items-center">
                            <div className="max-w-[1024px] space-y-2">
                                <h4 className="text-dark text-xl font-bold">{`Get started with voices `}</h4>
                                <p className="text-sm font-medium text-gray-4">
                                    {`Your Brand enables Aye Assistant to access information unique to what you are writing, 
                                        as well as your specific voice(s) and style(s).`}
                                </p>
                            </div>
                            <button onClick={openSuccesPopUp} className="btn bg-green text-white font-bold">{`Add Brand`}</button>
                        </div>
                    </>
                    :
                    <div>
                        <div className="flex gap-4 items-end flex-wrap">
                            <h2 className="large-title">{`Your Brand`}</h2>
                            <button type="button" className="btn flex gap-2 items-center ms-auto !bg-green"
                                onClick={openSuccesPopUp}
                            >
                                <span>{`Add Brand`}</span>
                            </button>
                        </div>

                        <div className="bg-white mt-6">
                            <div className="flex items-left md:items-center gap-3 border-[#ECECEC] p-3 flex-col md:flex-row">
                                <div className="flex items-center gap-3">
                                    {/* All Check Box Button */}
                                    <button type="button"
                                        onClick={handleSelectAllClick}
                                        className="w-[60px] h-[33px] flex justify-center gap-2 items-center p-1 text-base font-medium border rounded-md border-gray-8 -ml-1
                                        hover:border-dark">
                                        <input checked={selectAll} type="checkbox" className="h-4 w-4 accent-purple cursor-pointer rounded-none bg-light-gray-3 align-middle"
                                        // ref={(el) => el && (el.indeterminate = checkboxes.some((checkbox) => checkbox && !selectAll))}
                                        />
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path fill="#707070" fillRule="evenodd" d="m7.976 10.072l4.357-4.357l.62.618L8.284 11h-.618L3 6.333l.619-.618l4.357 4.357z" clipRule="evenodd" /></svg>
                                        </span>
                                    </button>

                                    {/* Archive / Unarchive  Button */}
                                    <button
                                        onClick={() => setFilterType(!filterType)}
                                        onMouseOver={() => setHoverColor(1)}
                                        onMouseOut={() => setHoverColor(null)}
                                        type="button"
                                        className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8
                                        hover:text-dark hover:border-dark  h-[33px] px-2"
                                    >
                                        <ArchiveIcon color={hoverColor === 1 ? "#2D2D2D" : "#AEAEAE"} />
                                        <span>
                                            {filterType ? `Unarchive` : `Archive`}
                                        </span>
                                    </button>
                                </div>
                                <div className='flex gap-2'>
                                    <button
                                        onClick={() => {
                                            if (showSetting) {
                                                setShowSetting(!showSetting)
                                                setFilter({ name: '', email: '' })
                                                setCurrentPage(1)
                                                BrandVoiceData(1)
                                                setRefresh((e) => !e)
                                            } else setShowSetting(true)
                                        }}
                                        type="button" className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8 hover:text-dark hover:border-dark  h-[33px] px-2"
                                    >
                                        <ArchiveIcon color={showSetting ? "#2D2D2D" : "#AEAEAE"} />
                                        <span style={{ color: showSetting ? "#2D2D2D" : "#AEAEAE" }}>
                                            {!showSetting ? `Show Filters` : `Hide Filters`}
                                        </span>
                                    </button>
                                    {
                                        showSetting &&
                                        <div className="flex items-center gap-2">
                                            <input
                                                onChange={(event) => setFilter({ name: event.target.value })}
                                                className={'w-60 h-[30px] text-dark text-xs border border-dark rounded-lg px-2'} type={'name'} placeholder={'Enter Name'}
                                            />
                                            <button
                                                onClick={() => {
                                                    setCurrentPage(1)
                                                    BrandVoiceData(1)
                                                }}
                                                type={'button'} className={'w-24 h-[30px] text-dark text-sm font-medium rounded-lg border border-dark hover:bg-dark hover:text-white'}
                                            >
                                                {`Apply`}
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>


                            <div className="flex bg-white mt-3 flex-col overflow-y-auto min-h-[500px] w-full">
                                <BrandKnowledgeTable
                                    sortedBrandListing={sortedBrandListing} requestSort={requestSort} session={session?.data} refresh={refresh} setRefresh={setRefresh}
                                    sortConfig={sortConfig} toggle={toggle} setToggle={setToggle} navigate={navigate} filterType={filterType}
                                    handleCheckboxClick={handleCheckboxClick} checkboxes={checkboxes}
                                />
                                <div className='flex justify-end'>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={4}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={currentPage - 1}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};

function BrandKnowledgeTable({ sortedBrandListing, requestSort, session, refresh, setRefresh, sortConfig, toggle, setToggle, navigate, filterType, handleCheckboxClick, checkboxes }) {
    return (
        <table className="w-full table-auto text-left mt-2 text-xs md:text-sm ">
            <thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!p-3 [&_th]:text-[15px] [&_th]:!text-[#7B7B7B] ">
                <tr>
                    <th className="w-12 text-center">
                        <span className="sr-only">
                            <input type="checkbox" className="h-4 w-4 accent-purple rounded-none" />
                        </span>
                    </th>
                    <th onClick={() => requestSort('name')} className="cursor-pointer" >
                        Brand {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                    </th>
                    <th >
                        Created by
                    </th>
                    <th onClick={() => requestSort('createdAt')} className="cursor-pointer">
                        Created on {sortConfig.key === 'createdAt' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                    </th>
                    <th onClick={() => requestSort('status')} className="cursor-pointer">
                        Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                    </th>
                    <th>
                        <span className="sr-only">Actions</span>
                    </th>
                </tr>
            </thead>
            <tbody className="[&_td]:py-4 [&_td]:border-b [&_td]:border-white [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
                {
                    sortedBrandListing?.length !== 0 && sortedBrandListing?.map((data, index) => {
                        let Condition = data?.status !== 'ARCHIVE'
                        if (filterType === true) Condition = data?.status === 'ARCHIVE'
                        if (Condition) {
                            return (
                                <BrandKnowledgeRow
                                    key={index} index={index}
                                    data={data}
                                    toggle={toggle}
                                    setToggle={setToggle}
                                    refresh={refresh}
                                    setRefresh={setRefresh}
                                    session={session}
                                    handleCheckboxClick={handleCheckboxClick}
                                    checkboxes={checkboxes}
                                    navigate={navigate}
                                />
                            )
                        } else return '';
                    })
                }
            </tbody>
        </table>
    )
}

function BrandKnowledgeRow({ index, data, toggle, setToggle, refresh, setRefresh, session, handleCheckboxClick, checkboxes, navigate }) {
    const [selectedId, setSelectedId] = useState('')
    const [openTextPopUp, hideTextPopUp] = useModal(() => (
        <BrandVoiceTextModel hide={hideTextPopUp} id={selectedId} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} />
    ), [selectedId, refresh, setRefresh]);

    const [openFilePopUp, hideFilePopUp] = useModal(() => (
        <BrandVoiceFileModel hide={hideFilePopUp} id={selectedId} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} />
    ), [selectedId, refresh, setRefresh]);

    const [openLinkPopUp, hideLinkPopUp] = useModal(() => (
        <BrandVoiceLinkModel hide={hideLinkPopUp} id={selectedId} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} />
    ), [selectedId, refresh, setRefresh]);

    const dropdownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(null);
            }
        }
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [setToggle]);

    function convertDateFormat(dateString) {
        const originalDate = new Date(dateString);

        const day = originalDate.getDate();
        const month = originalDate.getMonth() + 1; // Months are zero-based
        const year = originalDate.getFullYear();

        // Format the date as "MM/DD/YYYY"
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }

    async function Archive(id, type) {
        await axios.get(`${Path.DeleteBrandVoiceByID}${id}?status=${type}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            console.log(error?.response?.data)
            setToggle(null)
        })
    }

    async function Delete(id) {
        await axios.get(`${Path.DeleteBrandVoiceByID}${id}?status=DELETED`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            console.log(error?.response?.data)
            setToggle(null)
        })
    }


    async function Failed(id, data) {
        await axios.put(`${Path.RetryBrandVoiceByID}${id}`,
            {
                name: data?.createdBy?.firstName + data?.createdBy?.lastName,
                content: data.content,
                type: data.type,
                tags: data.tag,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setToggle(null)
        })
    }


    return (
        <tr key={index} className="bg-grey-4">
            <td className="w-12 text-center" >
                <input
                    checked={checkboxes[index] || false}
                    onChange={() => handleCheckboxClick(index)}
                    type="checkbox"
                    className="h-4 w-4  accent-purple rounded-none" />
            </td>
            <td onClick={() => navigate(`/administration/edit-Voice?id=${data._id}`)} className="text-dark cursor-pointer">
                {data?.name}
            </td>

            <td className="text-dark">{data?.createdBy?.firstName} {data?.createdBy?.lastName}</td>
            <td className="text-dark">{convertDateFormat(data?.createdAt)}</td>
            <td className={data?.status == "ACTIVE" ? "text-green" : data?.status == "ARCHIVE" ? "text-yellow" : " "}>{
                data?.status == "INPROGRESS" ? "IN PROGRESS" : data?.status
            }</td>

            <td className="relative text-center w-24" ref={dropdownRef}>
                <div className="flex items-center justify-center">
                    <button className="text-center "
                        onClick={(event) => {
                            event.stopPropagation();
                            toggle !== index ? setToggle(index) : setToggle(null)
                        }
                        }>
                        <img width={30} height={30} className="mx-auto" src={actionIcon} alt='' />
                    </button>
                </div>
                <div className={`${toggle === index ? 'block' : 'hidden'} bg-white absolute right-8 z-10`}>
                    <ul className="text-sm w-[188px] text-left shadow-[2px_4px_7px_4px_rgba(0,0,0,0.07)]">
                        {
                            data?.status == "ACTIVE" ?
                                <li
                                    className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                    onClick={() => Archive(data?._id, "ARCHIVE")}
                                >
                                    Archive
                                </li>
                                :
                                <li
                                    className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                    onClick={() => Archive(data?._id, "ACTIVE")}
                                >
                                    Active
                                </li>
                        }
                        {
                            data?.status == "FAILED" &&
                            <li
                                className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                onClick={() => Failed(data?._id, data)}
                            >
                                Retry
                            </li>
                        }
                        <li
                            className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                            onClick={() => {
                                Swal.fire({
                                    title: `Are you sure you want to delete brand?`,
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#7F5FEE",
                                    cancelButtonColor: "#F86E6E",
                                    confirmButtonText: "Yes"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        Delete(data?._id)
                                    }
                                })
                            }}
                        >
                            Delete
                        </li>
                    </ul>
                </div>
            </td>
        </tr>
    );
};