import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import { AuthContext } from '../_providers/AuthProvider';
import { FullScreenLoader } from '../components/common/FullScreenLoader';
import { Path } from '../helper/path';
import axios from 'axios';

export default function DashboardPage() {
    const Pathname = useLocation()
    const Router = useNavigate()
    const { session, status } = useContext(AuthContext);
    let Params = []
    if (Pathname.pathname.includes("/campaigns/")) {
        const data = Pathname.pathname.split("/")
        Params = data[data.length - 1]
    }

    useEffect(() => {
        if(status.loading === false){
            if(session?.data?.token === ''){ 
                status.logout()
                Router("/")
            }else{
                async function TokenTest() {
                    await axios.get(Path.BrandKnowledgeTextBase, {
                        headers: {
                            Authorization: "Bearer " + session?.data?.token,
                        },
                    }).then((success) => {
                        // console.log('')
                    }).catch((error) => {
                        status.logout()
                        Router("/")
                    })
                }
                TokenTest()
            }
        }
    }, [session, status])

    if (session && !session?.data?.token) {
        return <FullScreenLoader />
    } else {
        return (
            <div className='w-full h-[100dvh] overflow-hidden'>
                {/* {
                    Pathname.pathname.includes("/campaigns/")
                        ?
                        <CampaignHeader />
                        :
                        <Header />
                } */}
                <Header/>
                <div className='w-full h-[calc(100dvh-65px)] flex'>
                    <div className="h-screen flex flex-col duration-300 ease-linear border-border-gray border-r bg-white relative">
                        <Sidebar />
                    </div>
                    <div className='flex-1 min-h-full bg-grey-5 overflow-y-auto p-5 md:py-6 md:px-8'>
                        <Outlet />
                    </div>
                </div>
            </div>
        )
    }
}