import React, { useContext, useEffect, useRef, useState } from "react";
import AddNewCampaignModel from "../model/AddNewCampaignModel";
import { Icon } from "@iconify/react";
import actionIcon from "../../assets/images/brand-management/action-Icon.svg"
import ReactSwitch from 'react-switch';
import axios from "axios";
import { AuthContext } from "../../_providers/AuthProvider";
import { Path } from "../../helper/path";
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { useNavigate } from "react-router-dom";
import { useModal } from "react-modal-hook";
import InviteUserModel from "../model/InviteUserModel";
import { FullScreenLoader } from "../common/FullScreenLoader";
import { ArchiveIcon, ExportIcon, FilterIcon, SearchIcon } from "../../helper/SvgIcons";
import Swal from "sweetalert2";

export default function CampaignSummary() {
    const { session } = useContext(AuthContext)
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState([])
    const [filterType, setFilterType] = useState(false);
    const [isLoader, setIsLoader] = useState(false)
    const [hoverColor, setHoverColor] = useState(null)
    const [hide, setHide] = useState({
        status: true,
        id: ''
    })

    const [checkboxes, setCheckboxes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    // Function to handle individual checkbox click
    const handleCheckboxClick = (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setCheckboxes(updatedCheckboxes);

        // Check if all checkboxes are checked
        const allChecked = updatedCheckboxes.every((checkbox) => checkbox);
        setSelectAll(allChecked);
    };

    // Function to handle "Select All" checkbox click
    const handleSelectAllClick = () => {
        const allChecked = !selectAll;
        setSelectAll(allChecked);

        // Update the state of all checkboxes
        const updatedCheckboxes = new Array(data.length).fill(allChecked);
        setCheckboxes(updatedCheckboxes);
    };


    useEffect(() => {
        setIsLoader(true)
        async function GetData() {
            await axios.get(Path.CreateORGetCampaign,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setIsLoader(false)
                const sortedData = success.data.sort((a, b) => {
                    return new Date(b.updatedAt) - new Date(a.updatedAt);
                });
                setData(sortedData);
            }).catch((error) => {
                console.log(error)
                setIsLoader(false)
            })
        }
        GetData();
    }, [refresh, session?.data?.token])

    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <AddNewCampaignModel hide={hide} setHide={setHide} refresh={refresh} setRefresh={setRefresh} session={session?.data?.token} />
            {data?.length === 0
                ?
                <>
                    <div className="min-h-full flex flex-col gap-7 text-center justify-center items-center">
                        <div>
                            <h4 className="text-dark text-xl font-bold">
                                Right now you have no campaign to show
                            </h4>
                            <p className="text-xl font-medium">
                                Start creating your first campaign.
                            </p>
                        </div>
                        <button onClick={() => setHide({ status: false, id: '' })} className="btn bg-purple text-white font-bold">
                            {`Let’s Get Started`}
                        </button>
                    </div>
                </>
                :
                <div className="w-full min-h-full flex flex-col gap-6 select-none">
                    <CampaignSubHeader openModel={setHide} session={session.data} />
                    <div className="bg-white">
                        <div className="flex items-left md:items-center gap-3 border-[#ECECEC] p-3 flex-col md:flex-row">
                            <div className="flex items-center gap-3">
                                {/* All Check Box Button */}
                                <button
                                    type="button"
                                    onClick={handleSelectAllClick}
                                    className="w-[60px] h-[33px] flex justify-center gap-2 items-center p-1 text-base font-medium border rounded-md border-gray-8 -ml-1
                                 hover:border-dark"
                                >
                                    <input
                                        checked={selectAll}
                                        type="checkbox"
                                        className="h-4 w-4 accent-purple cursor-pointer rounded-none bg-light-gray-3 align-middle"
                                    // ref={(el) => el && (el.indeterminate = checkboxes.some((checkbox) => checkbox && !selectAll))}
                                    />
                                    <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fill="#707070"
                                                fillRule="evenodd"
                                                d="m7.976 10.072l4.357-4.357l.62.618L8.284 11h-.618L3 6.333l.619-.618l4.357 4.357z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                </button>

                                {/* Select box */}
                                {/* <select className="w-[144px] text-base text-gray-6 font-medium h-[33px] py-1 ps-2 pr-6 rounded-lg border border-gray-8 outline-0 bg-[url('/src/assets/images/dashboard/select-down-icon.svg')] bg-no-repeat bg-[95%] bg-[length:1.3rem] appearance-none hover:text-dark hover:border-dark ">
                                    <option value="" disabled selected>
                                        All
                                    </option>
                                </select> */}

                                {/* Archive / Unarchive  Button */}
                                <button
                                    onClick={() => setFilterType(!filterType)}
                                    onMouseOver={() => setHoverColor(1)}
                                    onMouseOut={() => setHoverColor(null)}
                                    type="button"
                                    className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8
                                hover:text-dark hover:border-dark  h-[33px] px-2"
                                >
                                    <ArchiveIcon
                                        color={
                                            hoverColor === 1
                                                ? "#2D2D2D"
                                                : "#AEAEAE"
                                        }
                                    />
                                    <span>
                                        {filterType ? `Unarchive` : `Archive`}
                                    </span>
                                </button>

                                {/* Campaigns Search Bar */}
                                {/* <div
                                    className="relative w-60 lg:w-96 h-[33px]"
                                    onMouseOver={() => setHoverColor(2)}
                                    onMouseOut={() => setHoverColor(null)}
                                >
                                    <span className="absolute top-[8px] left-[8px] w-[18px] h-[18px]">
                                        <SearchIcon
                                            color={
                                                hoverColor === 2
                                                    ? "#2D2D2D"
                                                    : "#AEAEAE"
                                            }
                                        />
                                    </span>
                                    <input
                                        className="w-full rounded-lg border py-1 pl-10 pr-4 outline-none border-gray-8
                                    hover:text-dark hover:border-dark text-gray-6"
                                        type="text"
                                        placeholder="Search in Campaigns"
                                    />
                                </div> */}
                            </div>
                            <div className="flex items-center gap-3 self-start md:ml-auto">
                                {/* Latest Edited Button */}
                                {/* <button
                                    onMouseOver={() => setHoverColor(3)}
                                    onMouseOut={() => setHoverColor(null)}
                                    type="button"
                                    className="min-w-max text-base text-gray-6 font-medium  h-[33px] p-2 rounded-lg flex  items-center gap-2 border border-gray-8
                                        hover:text-dark hover:border-dark ml-auto"
                                >
                                    <FilterIcon
                                        color={
                                            hoverColor === 3 ? "#2D2D2D" : "#AEAEAE"
                                        }
                                    />
                                    <span>{`Latest Edited`}</span>
                                </button> */}

                                {/* Export List Button */}
                                {/* <button
                                    onMouseOver={() => setHoverColor(4)}
                                    onMouseOut={() => setHoverColor(null)}
                                    className="min-w-max text-base font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8
                                    hover:text-dark hover:border-dark  h-[33px] px-2"
                                >
                                    <ExportIcon
                                        color={
                                            hoverColor === 4 ? "#2D2D2D" : "#AEAEAE"
                                        }
                                    />
                                    <span>{`Export List`}</span>
                                </button> */}
                            </div>
                        </div>

                        <div className="overflow-auto">
                            <Table TableData={data} session={session?.data?.token} refresh={refresh} setRefresh={setRefresh} handleCheckboxClick={handleCheckboxClick}
                                checkboxes={checkboxes} filterType={filterType} />
                        </div>
                    </div>
                </div>
            }
        </>
    );
};


function CampaignSubHeader({ openModel, session }) {
    const [openSuccesPopUp, hideSuccesPopUp] = useModal(() => (
        <InviteUserModel sessionData={session} hide={hideSuccesPopUp} />
    ));

    return (
        <div className='w-full flex justify-between items-start'>
            <div className='flex flex-col justify-center items-start'>
                <span className='large-title '>
                    {`Campaigns`}
                </span>
                <span className='sub-title'>
                    {`List of all the campaigns`}
                </span>
            </div>
            <div className='flex gap-2'>
                <button onClick={openSuccesPopUp} className='btn !bg-white border  !border-grey-6 text-grey-6'>

                    {`Invite User`}
                </button>
                <button className='btn'>
                    <Icon
                        icon="bx:plus-medical"
                        width={12}
                        height={12}
                    />
                    <span onClick={() => openModel({ status: false, id: '' })}>
                        {`New Campaign`}
                    </span>
                </button>
            </div>
        </div >
    );
};

function Table({ TableData, session, refresh, setRefresh, handleCheckboxClick, checkboxes, filterType }) {
    return (
        <div>
            <div className="overflow-auto">
                <table className="w-full table-auto text-left mt-0 text-sm lg:text-base">
                    <tbody className="[&_tr]:border-l-2 [&_tr]:border-l-green [&_td]:p-3 ">
                        {
                            TableData && TableData?.map((data, index) => {
                                let Condition = data?.status !== 'ARCHIVE'
                                if (filterType === true) Condition = data?.status === 'ARCHIVE'
                                if (Condition)
                                    return (
                                        <Row key={index} index={index} data={data} session={session} refresh={refresh} setRefresh={setRefresh} handleCheckboxClick={handleCheckboxClick}
                                            checkboxes={checkboxes} />
                                    );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function Row({ data, session, refresh, setRefresh, handleCheckboxClick, checkboxes, index }) {
    const navigate = useNavigate()

    const [toggle, setToggle] = useState(false)
    const [active, setActive] = useState(data?.status == "ACTIVE" ? true : false)
    const [hide, setHide] = useState({
        status: true,
        id: ''
    })

    const dropdownRef = useRef(null);

    useEffect(() => {
        //   Function to handle outside click
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(null);
            }
        }
        //   Bind the event listener
        document.addEventListener('click', handleClickOutside);
        //   Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    async function updateStatus(id, type) {
        await axios.get(`${Path.CampaignActions}${id}?status=${type}`,
            {
                headers: {
                    Authorization: "Bearer " + session,
                }
            }
        ).then((success) => {
            showSuccessMessage(`Campaign is ${type.toLowerCase() == 'active' ? 'enabled' : 'disabled'} successfully.`)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setToggle(null)
        })
    }

    async function Archive(id, type) {
        await axios.get(`${Path.CampaignActions}${id}?status=${type}`,
            {
                headers: {
                    Authorization: "Bearer " + session,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setToggle(null)
        })
    }

    return (
        <tr className="bg-[#F9F9F9] border-t-2 border-[#fff] [&_tr]:border-l-2 [&_tr]:border-l-green cursor-pointer">
            <AddNewCampaignModel hide={hide} setHide={setHide} refresh={refresh} setRefresh={setRefresh} session={session} />
            <td className="w-10 text-center">
                <input
                    checked={checkboxes[index] || false}
                    onChange={() => handleCheckboxClick(index)}
                    type="checkbox"
                    className="h-4 w-4  accent-purple rounded-none" />
            </td>
            <td>
                <div className="border-r border-dashed border-gray-6" onClick={() => data?.status == "ACTIVE" && navigate(`/campaigns/onboarding-campaign?id=${data?._id}`)}>
                    <h4 className="font-bold text-black-1 text-[15px]">
                        {data?.name}
                    </h4>
                    <div className="flex gap-3 font-medium text-sm mt-1">
                        <span className="text-purple">
                            {data?.brandId?.name}
                        </span>
                    </div>
                </div>
            </td>
            <td className="ml-auto w-[375px] md:w-[550px]">
                <div className="flex border-r border-dashed border-gray-6 pr-5 text-sm gap-4 justify-between ">
                    <div>
                        <h3 className="text-dark font-bold">
                            {data?.analytics?.sent}
                        </h3>
                        <p className="text-[#888888]">
                            Sent
                        </p>
                    </div>
                    <div>
                        <h3 className="text-dark font-bold">
                            {data?.analytics?.delivered}
                        </h3>
                        <p className="text-[#888888]">
                            Delivered
                        </p>
                    </div>
                    <div>
                        <h3 className="text-dark font-bold">
                            {data?.analytics?.click}
                        </h3>
                        <p className="text-[#888888]">
                            Click
                        </p>
                    </div>
                    <div>
                        <h3 className="text-dark font-bold">
                            {data?.analytics?.openCount}

                        </h3>
                        <p className="text-[#888888]">
                            Open
                        </p>
                    </div>
                    <div>
                        <h3 className="text-dark font-bold">
                            {data?.analytics?.bounced}
                        </h3>
                        <p className="text-[#888888]">
                            Bounced
                        </p>
                    </div>
                </div>
            </td>
            <td className="w-28">
                <div className="flex space-x-3 justify-end items-center">
                    <button>
                        <ReactSwitch
                            checked={active}
                            onChange={() =>
                                Swal.fire({
                                    title: `Are you sure you want to ${active ? 'disable' : 'enable'}?`,
                                    text: "You won't be able to revert this!",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#7F5FEE",
                                    cancelButtonColor: "#F86E6E",
                                    confirmButtonText: "Yes"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        updateStatus(data?._id, active ? "INACTIVE" : "ACTIVE")
                                        setActive(!active)
                                    }
                                })
                            }
                            className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition-all duration-300 ease-in-out transform`}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0 0 2px 2px #ccc"
                            onColor="#53D0A4"
                            offColor="#FF0000"
                            width={39}
                            height={20}
                            handleDiameter={12}
                        />
                    </button>
                    <div ref={dropdownRef}>
                        <button className="text-center" onClick={() => setToggle(toggle === data?._id ? null : data?._id)}>
                            <img width={30} height={30} src={actionIcon} alt='' />
                        </button>
                        <div className={`${toggle === data?._id ? 'block' : 'hidden'} bg-white absolute right-8 shadow z-10`}>
                            <ul className="text-sm w-[188px]">
                                {data?.status == "ACTIVE" &&
                                    <li
                                        onClick={() => {
                                            navigate(`/campaigns/onboarding-campaign?id=${data?._id}&activeTab=3`)
                                        }}
                                        className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                    >
                                        Edit
                                    </li>
                                }

                                {
                                    data?.status == "ACTIVE" ?
                                        <li
                                            className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                            onClick={() => Archive(data?._id, "ARCHIVE")}
                                        >
                                            Archive
                                        </li>
                                        :
                                        <li
                                            className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                            onClick={() => Archive(data?._id, "ACTIVE")}
                                        >
                                            Active
                                        </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};