import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Breadcrumb from "../common/Breadcrumbs";
import axios from "axios";
import { Path } from "../../helper/path";
import { AuthContext } from "../../_providers/AuthProvider";
import actionIcon from "../../assets/images/brand-management/action-Icon.svg"
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { FullScreenLoader } from "../common/FullScreenLoader";
import { useNavigate } from "react-router-dom";
import { ArchiveIcon } from "../../helper/SvgIcons";
import ReactPaginate from "react-paginate";


export default function RuleListing() {
    const [visibility, setVisibility] = useState(false)
    const { session } = useContext(AuthContext);
    const [refresh, setRefresh] = useState(false)
    const [isLoader, setIsLoader] = useState(true)
    // const [checkboxes, setCheckboxes] = useState([]);
    // const [selectAll, setSelectAll] = useState(false);
    const [data, setData] = useState([]);
    const [showSetting, setShowSetting] = useState(false);
    const [filter, setFilter] = useState({ campaignname: '', ruleName: '' })
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    // Function to handle individual checkbox click
    // const handleCheckboxClick = (index) => {
    //     const updatedCheckboxes = [...checkboxes];
    //     updatedCheckboxes[index] = !updatedCheckboxes[index];
    //     setCheckboxes(updatedCheckboxes);

    //     // Check if all checkboxes are checked
    //     const allChecked = updatedCheckboxes.every((checkbox) => checkbox);
    //     setSelectAll(allChecked);
    // };

    // Function to handle "Select All" checkbox click
    // const handleSelectAllClick = () => {
    //     const allChecked = !selectAll;
    //     setSelectAll(allChecked);

    //     // Update the state of all checkboxes
    //     const updatedCheckboxes = new Array(data.length).fill(allChecked);
    //     setCheckboxes(updatedCheckboxes);
    // }

    const getData = async (pageNumber) => {
        setIsLoader(true)
        await axios.get(Path.rulesEngineNew,
            {
                params: {
                    page: pageNumber,
                    size: 10,
                    campaignName: filter.campaignname,
                    ruleName: filter.ruleName,
                    sortKey: sortConfig.key,
                    sortOrder: sortConfig.direction,
                },
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            console.log(success?.data)
            setPageCount(success?.data?.totalPages)
            setData(success?.data?.rules)
        }).catch((error) => {
            console.log(error?.response?.data)
        }).finally(() => {
            setIsLoader(false)
        })
    }

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
        getData(selectedPage);
    };

    const requestSort = key => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedRuleListing = useMemo(() => {
        let sortableItems = [...data];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [data, sortConfig]);

    useEffect(() => {
        session?.data?.token && getData(currentPage);
    }, [refresh, session?.data?.token])
    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div className="w-full flex flex-col gap-5">
                <RuleListingSubHeader session={session?.data} visibility={visibility} event={setVisibility} />
                <div className="bg-white">
                    <div className="flex items-left md:items-center gap-3 border-[#ECECEC] p-3 flex-col md:flex-row">
                        <div className="flex items-center gap-3">
                            {/* All Check Box Button */}
                            {/* <button type="button"
                                onClick={handleSelectAllClick}
                                className="w-[60px] h-[33px] flex justify-center gap-2 items-center p-1 text-base font-medium border rounded-md border-gray-8 -ml-1
                                        hover:border-dark">
                                <input checked={selectAll} type="checkbox" className="h-4 w-4 accent-purple cursor-pointer rounded-none bg-light-gray-3 align-middle"
                                // ref={(el) => el && (el.indeterminate = checkboxes.some((checkbox) => checkbox && !selectAll))}
                                />
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path fill="#707070" fillRule="evenodd" d="m7.976 10.072l4.357-4.357l.62.618L8.284 11h-.618L3 6.333l.619-.618l4.357 4.357z" clipRule="evenodd" /></svg>
                                </span>
                            </button> */}
                            <div className='flex gap-2'>
                                <button
                                    onClick={() => {
                                        if (showSetting) {
                                            setShowSetting(!showSetting)
                                            setFilter({ campaignname: '', ruleName: '' })
                                            setCurrentPage(1)
                                            getData(1)
                                            setRefresh((e) => !e)
                                        } else setShowSetting(true)
                                    }}
                                    type="button" className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8 hover:text-dark hover:border-dark  h-[33px] px-2"
                                >
                                    <ArchiveIcon color={showSetting ? "#2D2D2D" : "#AEAEAE"} />
                                    <span style={{ color: showSetting ? "#2D2D2D" : "#AEAEAE" }}>
                                        {!showSetting ? `Show Filters` : `Hide Filters`}
                                    </span>
                                </button>
                                {
                                    showSetting &&
                                    <div className="flex items-center gap-2">
                                        <input
                                            onChange={(event) => setFilter((e) => ({ ...e, ruleName: event.target.value }))}
                                            className={'w-60 h-[30px] text-dark text-xs border border-dark rounded-lg px-2'} type={'name'} placeholder={'Enter Rule Name'}
                                        />
                                        <input
                                            onChange={(event) => setFilter((e) => ({ ...e, campaignname: event.target.value }))}
                                            className={'w-60 h-[30px] text-dark text-xs border border-dark rounded-lg px-2'} type={'name'} placeholder={'Enter Campaing Name..'}
                                        />
                                        <button
                                            onClick={() => {
                                                setCurrentPage(1)
                                                getData(1)
                                            }}
                                            type={'button'} className={'w-24 h-[30px] text-dark text-sm font-medium rounded-lg border border-dark hover:bg-dark hover:text-white'}
                                        >
                                            {`Apply`}
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="flex bg-white flex-col overflow-y-auto min-h-[500px] w-full">
                        <RuleTable session={session} refresh={refresh} setRefresh={setRefresh} requestSort={requestSort} sortConfig={sortConfig}
                            data={data} sortedRuleListing={sortedRuleListing}
                        />
                        <div className='flex justify-end'>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={4}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage - 1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function RuleTable({ session, refresh, setRefresh, Rules, data, requestSort, sortConfig, sortedRuleListing }) {
    return (
        <table className="w-full table-auto text-left mt-2 text-xs md:text-sm ">
            <thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!p-3 [&_th]:text-[15px] [&_th]:!text-[#7B7B7B] ">

                <tr>
                    {/* <th className="w-12 text-center">
                        <span className="sr-only">
                            <input type="checkbox" className="h-4 w-4 accent-purple rounded-none" />
                        </span>
                    </th> */}
                    <th onClick={() => requestSort('name')} className="cursor-pointer" >
                        Rule Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                    </th>
                    <th >
                        Campaign Name
                    </th>
                    <th onClick={() => requestSort('status')} className="cursor-pointer">
                        Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? <span className='text-[12px]'>▲</span> : <span className='text-[12px]'>▼</span>)}
                    </th>
                    <th className="text-center">Actions</th>
                </tr>
            </thead>
            <tbody className="[&_td]:py-4 [&_td]:border-b [&_td]:border-white [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
                {
                    sortedRuleListing?.length !== 0 && sortedRuleListing?.map((item, index) => {
                        return (
                            <Row key={index} item={item} session={session} refresh={refresh} setRefresh={setRefresh} index={index} />
                        )
                    })
                }
            </tbody>
        </table>
    );
};

function Row({ item, session, refresh, setRefresh, index }) {
    const [toggle, setToggle] = useState(false)
    const dropdownRef = useRef(null);
    const navigate = useNavigate()

    const handleDelete = async (id) => {
        await axios.delete(`${Path.rulesEngine}/${id}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
        }).catch((error) => {
            console.log(error?.response?.data)
            showErrorMessage(error?.response?.data?.error)
        }).finally(() => {
            setRefresh(!refresh)
        })
    }

    const handleStatusChange = async (id, status) => {
        await axios.get(`${Path.UpdateStatus}/${id}?status=${status}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
        }).catch((error) => {
            console.log(error?.response?.data)
            showErrorMessage(error?.response?.data?.error)
        }).finally(() => {
            setRefresh(!refresh)
        })
    }

    useEffect(() => {
        //   Function to handle outside click
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setToggle(false);
            }
        }
        //   Bind the event listener
        document.addEventListener('click', handleClickOutside);
        //   Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <tr key={item?._id} className="bg-grey-4">
            {/* <td className="w-14 text-center">
                <input
                    type="checkbox"
                    checked={checkboxes[index] || false}
                    onChange={() => handleCheckboxClick(index)}
                    className="h-4 w-4  accent-purple rounded-none" />
            </td> */}
            <td>
                {item?.name}
            </td>
            <td className="">
                {item?.campaignId?.name}
            </td>
            <td className={`${item?.status === "ACTIVE" ? "text-green" : "text-yellow"}`}>
                {item?.status ? (item?.status == 'DELETED' ? 'Archive' : item?.status) : "-"}
            </td>
            <td className="text-center relative" ref={dropdownRef}>
                <div className="flex items-center justify-center">
                    <button className="text-center " onClick={() => setToggle(!toggle)}>
                        <img width={30} height={30} className="mx-auto" src={actionIcon} alt='' />
                    </button>
                </div>
                <div className={`bg-white absolute right-8 z-10 ${toggle ? 'block' : 'hidden'}`}>
                    <ul className="text-sm w-[188px] rounded text-left shadow-[2px_4px_7px_4px_rgba(0,0,0,0.07)]">
                        {item?.status != "DELETED" && item?.status != "EXECUTE" &&
                            < li onClick={() => handleStatusChange(item?._id, "EXECUTE")} className=" font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer">
                                Execute
                            </li>
                        }
                        <li
                            onClick={
                                () => {
                                    navigate(`/rules-engine/edit/${item?._id}`)
                                }
                            }
                            className=" font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer">
                            Edit
                        </li>

                        {item?.status === "DELETED" ?
                            <li onClick={() => handleStatusChange(item?._id, "ACTIVE")} className=" font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer"
                            >
                                Active
                            </li>
                            :
                            <li onClick={() => handleDelete(item?._id)} className=" font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4 cursor-pointer"
                            >
                                Delete
                            </li>
                        }
                    </ul>
                </div>
            </td>
        </tr >


    )
}

function RuleListingSubHeader() {
    return (
        <div className='w-full flex justify-between items-start'>
            <div className='flex flex-col justify-center items-start'>
                <Breadcrumb />
                <span className='large-title'>
                    {`Rule List`}
                </span>
            </div>
            {/* <button
                onClick={openSuccesPopUp}
                className='flex justify-center items-center gap-3 rounded-full btn bg-green text-white hover:scale-105 duration-300'
            >
                <span>
                    {`Add Rule`}
                </span>
            </button> */}
        </div>
    );
};