import React, { useRef } from "react";
import searchIcon from "../../assets/images/common/Search.svg"
import sortIcon from "../../assets/images/campaigns/sort.svg"
import downloadIcon from "../../assets/images/campaigns/download.svg"
import { useModal } from "react-modal-hook";
import AddKnowledgeBaseModel from "../model/AddKnowledgeBaseModel";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../_providers/AuthProvider";
import axios from "axios";
import { Path } from "../../helper/path";
import actionIcon from "../../assets/images/brand-management/action-Icon.svg"
import AddKnowledgeTextModel from "../model/AddKnowledgeTextModel";
import AddKnowledgeLinkModel from "../model/AddKnowledgeLinkModel";
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { Icon } from "@iconify/react";
import ReactPaginate from "react-paginate";
import AddKnowledgeFileModel from "../model/AddKnowledgeFileModel";
import { FullScreenLoader } from "../common/FullScreenLoader";
import { ArchiveIcon, ExportIcon, FilterIcon, SearchIcon } from "../../helper/SvgIcons";

export default function BrandKnowledgeBase() {
    const [filterType, setFilterType] = useState(false);
    const [toggle, setToggle] = useState(null)
    const [refresh, setRefresh] = useState(false)
    const { session } = useContext(AuthContext)
    const [isLoader, setIsLoader] = useState(false)
    const [brandData, setBrandData] = useState([])
    const [hoverColor, setHoverColor] = useState(null)



    const [openSuccesPopUp, hideSuccesPopUp] = useModal(() => (
        <AddKnowledgeBaseModel hide={hideSuccesPopUp} session={session} refresh={refresh} setRefresh={setRefresh} />
    ));



    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 10;

    const [activeBrandData, setActiveBrandData] = useState([]);
    const [archiveBrandData, setArchiveBrandData] = useState([]);
    const [isClosed, setIsClosed] = useState(true);

    const handleClose = () => {
        setIsClosed(!isClosed);
    };


    const [checkboxes, setCheckboxes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    // Function to handle individual checkbox click
    const handleCheckboxClick = (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setCheckboxes(updatedCheckboxes);

        // Check if all checkboxes are checked
        const allChecked = updatedCheckboxes.every((checkbox) => checkbox);
        setSelectAll(allChecked);
    };

    // Function to handle "Select All" checkbox click
    const handleSelectAllClick = () => {
        const allChecked = !selectAll;
        setSelectAll(allChecked);

        // Update the state of all checkboxes
        const updatedCheckboxes = new Array(brandData.length).fill(allChecked);
        setCheckboxes(updatedCheckboxes);
    };

    useEffect(() => {
        // Reset activePage when switching between "Active" and "Archive" modes
        setActivePage(1);
        // Filter 'ACTIVE' and 'ARCHIVE' data
        const activeData = brandData.filter(data => data.status === 'ACTIVE' || data.status === 'FAILED');
        const archiveData = brandData.filter(data => data.status === 'ARCHIVE' || data.status === 'FAILED');

        setActiveBrandData(activeData);
        setArchiveBrandData(archiveData);
    }, [brandData, filterType]);

    const paginatedActiveBrandData = activeBrandData.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
    const paginatedArchiveBrandData = archiveBrandData.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
    const filteredData = activeBrandData.length > 0 && filterType === false ? paginatedActiveBrandData : paginatedArchiveBrandData;
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };


    useEffect(() => {
        async function BrandKnowledgeData() {
            setIsLoader(true)
            await axios.get(Path.BrandKnowledgeTextBase, {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }).then((success) => {
                setIsLoader(false)
                setBrandData(success?.data)
            }).catch((error) => {
                setIsLoader(false)
                console.log(error)
            })
        }
        BrandKnowledgeData()
    }, [refresh, session?.data?.token])


    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            {
                brandData?.length === 0 ?
                    <>
                        <div className="min-h-full flex flex-col gap-10 text-center justify-center items-center">
                            <div className="max-w-[1024px] space-y-2">
                                <h4 className="text-dark text-2xl font-bold">{`Get started with Knowledge Base`}</h4>
                                <p className="text-lg/8 font-medium text-gray-4">
                                    {`Add facts about your brand to your Knowledge Base. Use your Knowledge Base when creating content in AI so that AI can write factually about your business, products and more!`}
                                </p>
                            </div>
                            <button onClick={openSuccesPopUp} className="btn bg-green text-white  font-bold text-base">{`Add Knowledge Base`}</button>
                        </div>
                    </>
                    :
                    <div>
                        <div className="flex gap-4 items-end flex-wrap">
                            <h2 className="large-title">Brand Management</h2>
                            <button type="button" className="btn flex gap-2 items-center ms-auto !bg-green"
                                onClick={() => { openSuccesPopUp() }}
                            >
                                <span>Add Knowledge Base</span>
                            </button>
                        </div>

                        <div className="bg-white mt-5">
                            <div className="flex items-center gap-3 p-3">

                                {/* All Check Box Button */}
                                <button type="button"
                                    onClick={handleSelectAllClick}
                                    className="w-[60px] h-[33px] flex justify-center gap-2 items-center p-1 text-base font-medium border rounded-md border-gray-8 -ml-1
                                 hover:border-dark">
                                    <input checked={selectAll} type="checkbox" className="h-4 w-4 accent-purple cursor-pointer rounded-none bg-light-gray-3 align-middle"
                                    // ref={(el) => el && (el.indeterminate = checkboxes.some((checkbox) => checkbox && !selectAll))}
                                    />
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><path fill="#707070" fillRule="evenodd" d="m7.976 10.072l4.357-4.357l.62.618L8.284 11h-.618L3 6.333l.619-.618l4.357 4.357z" clipRule="evenodd" /></svg>
                                    </span>
                                </button>


                                {/* Archive / Unarchive  Button */}
                                <button
                                    onClick={() => setFilterType(!filterType)}
                                    onMouseOver={() => setHoverColor(1)}
                                    onMouseOut={() => setHoverColor(null)}
                                    type="button"
                                    className="min-w-[127px] text-sm font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8
                                hover:text-dark hover:border-dark  h-[33px] px-2"
                                >
                                    <ArchiveIcon color={hoverColor === 1 ? "#2D2D2D" : "#AEAEAE"} />
                                    <span>
                                        {filterType ? `Unarchive` : `Archive`}
                                    </span>
                                </button>

                                {/* Campaigns Search Bar */}
                                <div className="relative w-60 lg:w-96 h-[33px]"
                                    onMouseOver={() => setHoverColor(2)}
                                    onMouseOut={() => setHoverColor(null)}
                                >
                                    <span className='absolute top-[8px] left-[8px] w-[18px] h-[18px]'>
                                        <SearchIcon color={hoverColor === 2 ? "#2D2D2D" : "#AEAEAE"} />
                                    </span>
                                    <input
                                        className='w-full rounded-lg border py-1 pl-10 pr-4 outline-none border-gray-8
                                    hover:text-dark hover:border-dark text-gray-6'
                                        type='text' placeholder='Search in Campaigns' />
                                </div>


                                {/* Latest Edited Button */}
                                <button
                                    onMouseOver={() => setHoverColor(3)}
                                    onMouseOut={() => setHoverColor(null)}
                                    type="button"
                                    className="text-base text-gray-6 font-medium  h-[33px] p-2 rounded-lg flex  items-center gap-2 border border-gray-8
                                    hover:text-dark hover:border-dark ml-auto"
                                >
                                    <FilterIcon color={hoverColor === 3 ? "#2D2D2D" : "#AEAEAE"} />
                                    {`Latest Edited`}
                                </button>

                                {/* Export List Button */}
                                <button
                                    onMouseOver={() => setHoverColor(4)}
                                    onMouseOut={() => setHoverColor(null)}
                                    className="w-[127px] text-base font-medium rounded-lg flex items-center gap-2 border text-gray-6 border-gray-8
                                hover:text-dark hover:border-dark  h-[33px] px-2">
                                    <ExportIcon color={hoverColor === 4 ? "#2D2D2D" : "#AEAEAE"} />
                                    {`Export List`}
                                </button>

                            </div>

                            <div className="">
                                {filteredData.length > 0 ? (
                                    <table className="w-full table-auto text-left mt-2 text-xs md:text-sm lg:!text-[15px]">
                                        <thead className="[&_th]:!border-t-0 [&_th]:!font-medium [&_th]:!p-3 [&_th]:text-sm [&_th]:!text-[#7B7B7B] [&_th]:lg:!text-base">
                                            <tr>
                                                <th className="w-12 text-center">
                                                    <span className="sr-only">
                                                        <input type="checkbox" className="h-4 w-4 accent-purple rounded-none" />
                                                    </span>
                                                </th>
                                                <th>Name</th>
                                                <th>Created by</th>
                                                <th>Created on</th>
                                                <th>Tags</th>
                                                <th>Status</th>
                                                <th>
                                                    <span className="sr-only">Actions</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="[&_td]:py-4 [&_td]:border-b [&_td]:border-white [&_td]:font-medium [&_td]:px-2 md:[&_td]:px-3">
                                            {filteredData.map((data, index) => {
                                                let Condition = data?.status !== 'ARCHIVE'
                                                if (filterType === true) Condition = data?.status === 'ARCHIVE'
                                                if (Condition) {
                                                    return (
                                                        <BrandKnowledgeRow
                                                            key={index} index={index}
                                                            data={data}
                                                            toggle={toggle}
                                                            setToggle={setToggle}
                                                            refresh={refresh}
                                                            setRefresh={setRefresh}
                                                            session={session}
                                                            handleCheckboxClick={handleCheckboxClick}
                                                            checkboxes={checkboxes}
                                                        />
                                                    );
                                                } else return null;
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="text-purple bg-grey-4 text-center h-52 flex mt-2 justify-center items-center bg">
                                        <div>Data not found</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="mt-4 flex justify-center">
                            {/* Active Status Pagination */}
                            {activeBrandData.length > 0 && filterType === false && (
                                <ReactPaginate
                                    previousLabel="<<"
                                    nextLabel=">>"
                                    breakLabel="..."
                                    pageCount={Math.ceil(activeBrandData.length / itemsPerPage)}
                                    pageRangeDisplayed={itemsPerPage}
                                    marginPagesDisplayed={2}
                                    onPageChange={({ selected }) => handlePageChange(selected + 1)}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />

                            )}

                            {/*     Archive Status Pagination */}
                            {archiveBrandData.length > 0 && filterType === true && (
                                <ReactPaginate
                                    previousLabel="<<"
                                    nextLabel=">>"
                                    breakLabel="..."
                                    pageCount={Math.ceil(archiveBrandData.length / itemsPerPage)}
                                    pageRangeDisplayed={itemsPerPage}
                                    marginPagesDisplayed={2}
                                    onPageChange={({ selected }) => handlePageChange(selected + 1)}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            )}
                        </div>
                    </div>
            }
        </>

    )
}

function BrandKnowledgeRow({ index, data, toggle, setToggle, refresh, setRefresh, session, handleCheckboxClick, checkboxes }) {
    const [selectedId, setSelectedId] = useState('')
    const [openTextPopUp, hideTextPopUp] = useModal(() => (
        <AddKnowledgeTextModel hide={hideTextPopUp} id={selectedId} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} />
    ), [selectedId, refresh, setRefresh]);

    const [openFilePopUp, hideFilePopUp] = useModal(() => (
        <AddKnowledgeFileModel hide={hideFilePopUp} id={selectedId} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} />
    ), [selectedId, refresh, setRefresh]);

    const [openLinkPopUp, hideLinkPopUp] = useModal(() => (
        <AddKnowledgeLinkModel hide={hideLinkPopUp} id={selectedId} refresh={refresh} setRefresh={setRefresh} setToggle={setToggle} session={session} />
    ), [selectedId, refresh, setRefresh]);

    function convertDateFormat(dateString) {
        const originalDate = new Date(dateString);
        const day = originalDate.getDate();
        const month = originalDate.getMonth() + 1; // Months are zero-based
        const year = originalDate.getFullYear();

        // Format the date as "MM/DD/YYYY"
        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }

    async function Archive(id, type) {
        await axios.get(`${Path.DeleteBrandKnowledgeByID}${id}?status=${type}`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setToggle(null)
        })
    }

    async function Failed(id, data) {
        await axios.put(`${Path.UpdateBrandKnowledgeByID}${id}`,
            {
                name: data?.createdBy?.firstName + data?.createdBy?.lastName,
                content: data.content,
                type: data.type,
                tags: data.tag,
            },
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                },
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setToggle(null)
        })
    }

    async function Delete(id) {
        await axios.get(`${Path.DeleteBrandKnowledgeByID}${id}?status=DELETED`,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            showSuccessMessage(success?.data?.message)
            setRefresh(!refresh)
            setToggle(null)
        }).catch((error) => {
            showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            setToggle(null)
        })
    }

    const dropdownRefBase = useRef(null);

    useEffect(() => {
        //   Function to handle outside click
        function handleClickOutside(event) {
            if (dropdownRefBase.current && !dropdownRefBase.current.contains(event.target)) {
                setToggle(null);
            }
        }
        //   Bind the event listener
        document.addEventListener('click', handleClickOutside);
        //   Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    return (
        <tr key={index} className="bg-grey-4">
            <td className="w-12 text-center" >
                <input
                    type="checkbox"
                    checked={checkboxes[index] || false}
                    onChange={() => handleCheckboxClick(index)}
                    className="h-4 w-4  accent-purple rounded-none" />
            </td>
            <td className="capitalize">
                {data?.name}
            </td>
            <td className="text-dark">{data?.createdBy?.firstName} {data?.createdBy?.lastName}</td>
            <td className="text-dark">{convertDateFormat(data?.createdAt)}</td>
            <td className="">
                <div className="flex gap-2 items-center  text-xs font-medium">

                    <span className='rounded-md p-2 text-green bg-light-green flex gap-2 items-center'>
                        {data?.tags[0]}
                        <Icon className='text-black text-md cursor-pointer' icon={'radix-icons:cross-2'} />
                    </span>

                    {
                        data?.tags?.length - 1 > 0 &&
                        <span title={data?.tags} className="bg-[#ECECEC] text-dark p-2 rounded-md">
                            {`+ ${data?.tags?.length - 1}`}
                        </span>
                    }
                </div>
            </td>
            <td className={data?.status == "ACTIVE" ? "text-green" : data?.status == "ARCHIVE" ? "text-yellow" : "text-red-color"}>{data?.status}</td>
            <td className="relative text-center w-24" ref={dropdownRefBase}>
                <div className="flex items-center justify-center">
                    <button className="text-center " onClick={(event) => {
                        event.stopPropagation();
                        toggle !== index ? setToggle(index) : setToggle(null)
                    }
                    }>
                        <img width={30} height={30} className="mx-auto" src={actionIcon} alt='' />
                    </button>
                </div>

                <div className={`${toggle === index ? 'block' : 'hidden'} bg-white absolute right-8 z-10`}>
                    <ul className="text-base w-[226px] text-left shadow-[2px_4px_7px_4px_rgba(0,0,0,0.07)]">
                        <li
                            className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                            onClick={() => {
                                setToggle(null)
                                setSelectedId(data?._id)
                                if (data?.type === 'TEXT') openTextPopUp()
                                else if (data?.type === 'URL') openLinkPopUp()
                                else if (data?.type === 'FILE') openFilePopUp()
                            }}
                        >
                            Edit
                        </li>
                        {
                            data?.status == "ACTIVE" ?
                                <li
                                    className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                    onClick={() => Archive(data?._id, "ARCHIVE")}
                                >
                                    Archive
                                </li>
                                :
                                <li
                                    className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                    onClick={() => Archive(data?._id, "ACTIVE")}
                                >
                                    Active
                                </li>
                        }
                        {
                            data?.status == "FAILED" &&
                            <li
                                className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                                onClick={() => Failed(data?._id, data)}
                            >
                                Retry
                            </li>
                        }
                        <li
                            className="cursor-pointer font-medium hover:font-bold hover:text-dark hover:bg-[#EAE3FF] text-[#A1A1A1] transition-all py-2 px-4"
                            onClick={() => Delete(data?._id)}
                        >
                            Delete
                        </li>
                    </ul>
                </div>
            </td>
        </tr>
    );
};