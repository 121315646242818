import React, { useEffect, useState } from "react";
import crossIcon from '../../assets/images/common/crossicons.svg';
import { showErrorMessage, showSuccessMessage } from "../../helper/showMessage";
import { object, string } from 'yup';

import { Validation } from '../../helper/Messages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FullScreenLoader } from "../common/FullScreenLoader";
import axios from "axios";
import { Path } from "../../helper/path";
import { Icon } from '@iconify/react';

export default function AddApolloCredentialModel({ hide, sessionData, selectedIntegration }) {
    const [isLoader, setIsLoader] = useState(false);
    const [showApiKey, setShowApiKey] = useState(selectedIntegration ? false : true);

    const validationSchema = object().shape({
        apiKey: string().trim().required(Validation?.APIRequired),
        userName: string().trim().required(Validation?.UserNameRequired),
    });

    const { register: registerCredential, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        if (selectedIntegration) {
            setIsLoader(true);
            axios.get(`${Path.GetAllIntegration}/${selectedIntegration?._id}`, {
                headers: {
                    Authorization: "Bearer " + sessionData?.data?.token
                }
            }).then((integration) => {
                setIsLoader(false);
                setValue('apiKey', integration?.data?.decryptCredentials?.apiKey);
                setValue('userName', integration?.data?.decryptCredentials?.userName);
            })
                .catch((error) => {
                    setIsLoader(false);
                    showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.');
                });
        }
    }, [selectedIntegration]);

    const onSubmit = async (data, event) => {
        event.preventDefault();
        setIsLoader(true);
        if (selectedIntegration) {
            axios.put(Path.GetAllIntegration,
                {
                    applicationName: 'Apollo',
                    integrationDetails: {
                        apiKey: data.apiKey,
                        userName: data.userName
                    },
                    integrationId: selectedIntegration?._id
                },
                {
                    headers: {
                        Authorization: "Bearer " + sessionData?.data?.token
                    }
                }).then((success) => {
                    setIsLoader(false);
                    showSuccessMessage('Apollo Credential are updated successfully.');
                    hide();
                }).catch((error) => {
                    setIsLoader(false);
                    showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.');
                });
        } else {
            axios.post(Path.GetAllIntegration,
                {
                    applicationName: 'Apollo',
                    integrationDetails: {
                        apiKey: data.apiKey,
                        userName: data.userName
                    }
                },
                {
                    headers: {
                        Authorization: "Bearer " + sessionData?.data?.token
                    }
                }).then((success) => {
                    setIsLoader(false);
                    showSuccessMessage('Apollo Credential Added Successfully.');
                    hide(true);
                }).catch((error) => {
                    setIsLoader(false);
                    showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.');
                });
        }
    };

    const toggleApiKeyVisibility = () => {
        setShowApiKey(prevState => !prevState);
    };

    return (
        <>
            {isLoader ? <FullScreenLoader /> : " "}
            <div className={` bg-black/40 absolute z-20 inset-0 flex items-center`}>
                <div className="bg-white p-[45px] rounded-[11px] shadow mx-auto max-w-[700px] w-full">
                    {/* Popup Header */}
                    <div className="h-10 md:h-14 flex justify-between">
                        <h4 className="text-left font-bold text-dark text-xl ">
                            {selectedIntegration ? 'Update' : 'Add'} Apollo Credential
                            {!selectedIntegration ? (
                                <a href="https://app.apollo.io/#/settings/integrations/api" target="_blank" className="text-blue-500">
                                    <small className="text-sm"> https://apollo.io</small>
                                </a>
                            ) : null}
                        </h4>
                        <div className="items-start flex cursor-pointer">
                            <img width={25} height={25} src={crossIcon} onClick={() => hide()} alt="" />
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="text-left">
                        {/* Popup Main Section */}
                        <div className="relative">
                            <label className="custom-label !text-neutral-500 !font-normal">API Key</label>
                            <input
                                type={showApiKey ? "text" : "password"}
                                name="apiKey"
                                placeholder="Enter API Key"
                                {...registerCredential("apiKey")}
                                className="custom-input !rounded-md"
                            />
                            <span className="absolute right-3 top-11 cursor-pointer" onClick={toggleApiKeyVisibility}>
                                {
                                    !showApiKey
                                        ?
                                        <Icon
                                            icon="codicon:eye-closed"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                        :
                                        <Icon
                                            icon="codicon:eye"
                                            className="icon-color"
                                            width={20}
                                            height={20}
                                        />
                                }
                            </span>
                            {errors.apiKey && <div className="error-css">{errors?.apiKey?.message}</div>}
                        </div>
                        <div className="mt-5">
                            <label className="custom-label !text-neutral-500 !font-normal">Username/Email</label>
                            <input
                                type="text"
                                name="userName"
                                placeholder="Enter Username/Email"
                                {...registerCredential("userName")}
                                className="custom-input !rounded-md"
                            />
                            {errors.userName && <div className="error-css">{errors?.userName?.message}</div>}
                        </div>
                        {/* Popup Footer */}
                        <div className="mt-10 flex justify-end items-center gap-6">
                            <button type="submit" className="w-full h-[54px] rounded-md bg-green text-white text-xl font-[500] hover:scale-105 duration-300 ">
                                {selectedIntegration ? 'Update' : 'Connect'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
