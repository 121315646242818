import React, { useEffect, useState } from 'react';

import backIcon from "../../assets/images/campaigns/backbutton.svg"
import axios from 'axios';
import { Path } from '../../helper/path';
import { showErrorMessage, showSuccessMessage } from '../../helper/showMessage';
import { Icon } from '@iconify/react';
import { BrandKnowledgeBaseModelValidation } from '../../helper/Messages';


export default function BrandVoiceFileModel({ id, AddBrandVoiceModelHide, refresh, setRefresh, hide, setToggle, session, EditGetData }) {
    const [file, setFile] = useState(null);
    const [data, setData] = useState(null)
    const [fileName, setFileName] = useState("");
    const [isLoader, setIsLoader] = useState(false)
    const [isFileLoader, setIsFileLoader] = useState(false)
    const [isDragging, setIsDragging] = useState(false);
    const [inputError, setInputError] = useState({
        name: { error: false, message: '' },
        file: { error: false, message: '' },
    });


    const handleCloseFilePopUp = () => {
        hide()
        setToggle(false)
    }

    const onSubmit = async (event, id) => {
        event.preventDefault();
        const name = event?.target?.name?.value.trim();
        setInputError({
            name: { error: !name, message: !name ? 'Name is required' : '' },
            file: { error: !file, message: !file ? 'File is required' : '' },
        });
        if (!name || !file) {
            return;
        }
        if (id !== '') {
            setIsLoader(true)
            await axios.put(`${Path.UpdateBrandVoiceByID}${id}`,
                {
                    name: event?.target?.name?.value,
                    content: file,
                    type: "FILE",
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                setIsLoader(false)
                setRefresh(!refresh)
                hide();
                showSuccessMessage(success?.data?.message)
                EditGetData && EditGetData();
            }).catch((error) => {
                setIsLoader(false)
                console.log(error)
                // showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })
        } else {
            setIsLoader(true)
            await axios.post(Path.BrandVoiceTextBase,
                {
                    name: event?.target?.name?.value,
                    content: file,
                    type: "FILE",
                },
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    },
                }
            ).then((success) => {
                setIsLoader(false)
                showSuccessMessage(success?.data?.message)
                setRefresh(!refresh)
                hide();
                AddBrandVoiceModelHide()
            }).catch((error) => {
                setIsLoader(false)
                console.log(error)
                showErrorMessage(error?.response?.data?.error || 'Something Went Wrong.')
            })
        }
    };
    useEffect(() => {
        async function GetData() {
            await axios.get(`${Path.GetBrandVoiceDataByID}${id}`,
                {
                    headers: {
                        Authorization: "Bearer " + session?.data?.token,
                    }
                }
            ).then((success) => {
                const fileUrl = success?.data?.content; // Assuming content holds the file URL
                if (fileUrl) {
                    const urlArray = fileUrl.split('/');
                    const extractedFileName = urlArray[urlArray.length - 1];
                    const decodedFileName = decodeURIComponent(extractedFileName);
                    setFileName(decodedFileName);
                }
                setFile(fileUrl)
                setData(success?.data)
            }).catch((error) => {
                console.log(error)
            })
        }
        id !== '' && GetData()
    }, [id, session?.data?.token])




    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);

        const selectedFile = e.dataTransfer.files[0];
        if (selectedFile.type === "application/pdf") {
            setFileName(selectedFile.name || "");
            FileUpload(selectedFile);
            setInputError((prevInputError) => ({
                ...prevInputError,
                file: { error: false, message: '' },
            }));
        } else {
            showErrorMessage("Please select a valid PDF file.");
        }
    };

    const handleInputChange = (field, value) => {
        setInputError((prevInputError) => ({
            ...prevInputError,
            [field]: { error: !value.trim(), message: !value.trim() ? BrandKnowledgeBaseModelValidation[field + 'Required'] : '' },
        }));
    }

    const FileUpload = async (selectedFile) => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        setIsFileLoader(true)
        await axios.post(Path.FileUpload,
            formData,
            {
                headers: {
                    Authorization: "Bearer " + session?.data?.token,
                }
            }
        ).then((success) => {
            setIsFileLoader(false)
            setFile(success?.data?.publicUrl)
        }).catch((error) => {
            console.log(error)
            setIsFileLoader(false)
            showErrorMessage(error?.data?.message || 'Something Went Wrong.')
        })
    }



    return (
        <div className={`bg-black/40 absolute z-20 inset-0 flex items-center`}>
            <div className="bg-white border border-gary-3 mx-auto max-w-[850px] w-full p-8">
                {/* Popup Header */}
                <div className="text-center h-16 ">
                    <h4 className="font-bold text-dark text-xl">
                        {
                            id !== '' ? 'Edit brand' : 'Add brand'
                        }
                    </h4>
                    <p className="text-neutral-500 text-sm">Give Aye Assistant facts to more accurately write about any topic.</p>
                </div>
                <form onSubmit={(e) => onSubmit(e, id)} className="mx-auto w-[95%]">
                    <div className="mt-4">
                        <label className="custom-label">
                            {`Name`}
                        </label>
                        <input onChange={(e) => handleInputChange('name', e.target.value)} type="name" defaultValue={data?.name} name="name" placeholder="Enter a Brand Name"
                            className={`custom-input !rounded-lg ${inputError.name.error ? 'border border-red-color' : ''}`}
                        />
                        {inputError.name.error && <div className="error-css">{inputError.name.message}</div>}

                    </div>
                    <div className="mt-4">
                        <label className="custom-label flex items-center gap-2">
                            <span>{`Upload File`}</span>
                            {isFileLoader && <div className='text-purple '> <Icon icon={'svg-spinners:tadpole'} /></div>}
                        </label>
                        <div
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            onClick={() => document.getElementById('pdfUpload').click()} className={`cursor-pointer ${inputError.file.error ? 'border border-red-color' : ''} relative flex justify-center items-center flex-col h-[269px] border-dashed border-2 ${isDragging ? 'border-blue-400' : 'border-[#EAEAEA]'} !rounded-lg `}>

                            {
                                file !== null
                                    ?
                                    <>
                                        <p className="text-dark text-sm text-center">
                                            {fileName}
                                        </p>
                                    </>
                                    : fileName ?
                                        <p className="text-dark text-sm text-center">
                                            {fileName}
                                        </p>
                                        :
                                        <p className="text-[#AEAEAE] text-sm text-center">
                                            Upload or drag here as it's accepting only pdf <br /> File size up to 25 MB
                                        </p>
                            }
                            <input
                                id="pdfUpload"
                                type="file"
                                className="hidden"
                                accept="application/pdf"
                                onChange={async (e) => {
                                    const selectedFile = e.target.files[0];
                                    if (selectedFile) {
                                        if (selectedFile.type === "application/pdf") {
                                            setFileName(selectedFile.name || "");
                                            FileUpload(selectedFile);
                                            setInputError((prevInputError) => ({
                                                ...prevInputError,
                                                file: { error: false, message: '' },
                                            }));
                                        } else {
                                            showErrorMessage("Please select a valid PDF file.");
                                            e.target.value = null;
                                        }
                                    }
                                }}
                            />
                        </div>
                        {inputError.file.error && <div className="error-css">{inputError.file.message}</div>}

                    </div>
                    {/* Popup Footer */}
                    <div className="mt-10 flex justify-end items-center gap-6">
                        <button onClick={handleCloseFilePopUp} type="button" className="px-4 flex gap-1 items-center border-r border-[#7B7B7B] text-neutral-500 ">
                            <img width={20} height={20} src={backIcon} alt="" />
                            <span className="text-sm font-bold">Back</span>
                        </button>
                        <button disabled={isFileLoader} onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }} type="submit"
                            className={`flex items-center gap-2 bg-green text-white rounded-full py-1 text-sm h-10 px-10  font-[600] font-base`}>
                            {
                                id !== ''
                                    ?
                                    <>
                                        {<span> {`Save`} </span>}
                                        {
                                            isLoader && <Icon icon={'svg-spinners:tadpole'} />
                                        }
                                    </>
                                    :
                                    <>
                                        {<span>{`Create`}</span>}
                                        {
                                            isLoader && <Icon icon={'svg-spinners:tadpole'} />
                                        }
                                    </>
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
